/* ./src/index.css */
@tailwind base;
@tailwind components;

@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  p {
    @apply text-sm;
  }
}

.divider p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.divider p span {
  padding: 0 10px;
  background: #fff;
}

* {
  scroll-behavior: smooth;
  /* For Firefox Scrollbars*/
  scrollbar-width: thin;  
}
/* For Chrome and other browsers except Firefox Scrollbars*/

::-webkit-scrollbar:horizontal {
  height: 0.5em;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}

:root {
  --swiper-navigation-color: #cecece;
  --swiper-navigation-size: 22px !important;
}